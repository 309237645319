// State
export const state = () => ({
	loading: false,
	currencies: [],
	exchangeRates: [],
	cancelToken: {},
})

// Actions
export const actions = {
	async currencies({ commit, state }, params) {
		commit('setLoading', true)
		commit('setCancelToken', { currencies: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`finances/currency`, { params, cancelToken: state.cancelToken.currencies.token })
			.then((response) => {
				const data = response?.data
				commit('setCurrency', data)
				commit('setLoading', false)
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
	},
	async updateStatus({ dispatch }, data) {
		const response = await this.$axios.put(`/finances/currency/status/${data.currency}`, data.form)
			.then((_) => {
				dispatch('master/baseCurrency', { is_active: true }, { root: true })
				return true
			})
			.catch(_ => {
				return false
			})
		return response
	},
	async update({ dispatch }, data) {
		const form = { ...data }
		const id = form.id;
		delete form.id
		try {
			const response = await this.$axios.put(`/finances/currency/${id}`, form).then((response) => {
				dispatch('currencies')
				return true;
			})
			return response;
		} catch (error) {
			this.$toast.error(error)
			return false
		}
	},
	async exchangeRates({ commit, state }, params) {
		commit('setLoading', true)
		commit('setCancelToken', { exchangeRates: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/finances/exchange-rate`, { params, cancelToken: state.cancelToken.exchangeRates.token })
			.then((response) => {
				const data = response?.data
				commit('exchangeRates', data)
				commit('setLoading', false)
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
	},
	async updateExchangeRate({ commit }, data) {
		commit('setLoading', true)
		const form = { ...data }
		const id = form.id;
		delete form.id
		delete form.to_currency
		form.is_active = true
		try {
			const response = await this.$axios.put(`/exchange-rate/${id}`, form).then((response) => {
				commit('setLoading', false)
				return true;
			})
			return response;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},

}

// Mutations
export const mutations = {
	setCurrency(state, response) {
		state.currencies = response
	},
	setLoading(state, data) {
		state.loading = data
	},
	exchangeRates(state, data) {
		state.exchangeRates = data
	},
	setCancelToken: (state, data) => {
		const oldData = { ...state.cancelToken }
		state.cancelToken = {
			...oldData, ...data
		}
	}
}

export const getters = {
	currencySort: (state) => (srt = "desc") => {
		const currencies = JSON.parse(JSON.stringify(state?.currencies))
		let sorts = []
		if (srt === 'desc') {
			sorts = currencies?.sort((firstCurrency, secondCurrency) => {
				return secondCurrency.is_active - firstCurrency.is_active
			})
		} else if (srt === 'asc') {
			sorts = currencies?.sort((firstCurrency, secondCurrency) => {
				return firstCurrency.is_active - secondCurrency.is_active
			})
		} else {
			sorts = currencies
		}
		return sorts;
	},
	exchangeRates: (state, getters, rootState) => {
		const activeCurrency = rootState.master.baseCurrency
		const arr = {}
		const exchangesInArray = []
		// grouping from currency
		state.exchangeRates.forEach(el => {
			const { from_currency: fCurrency, to_currency: tCurrency, rate, reversed_rate: rRate, id } = el;

			if (arr[fCurrency]) {
				arr[fCurrency].push({ is_show_lnd: el.is_show_lnd, is_show_lnd_reverse: el.is_show_lnd_reverse, to_currency: tCurrency, rate, reversed_rate: rRate, id })
			} else {
				arr[fCurrency] = []
				arr[fCurrency].push({ is_show_lnd: el.is_show_lnd, is_show_lnd_reverse: el.is_show_lnd_reverse, to_currency: tCurrency, rate, reversed_rate: rRate, id })
			}
		})

		// combining the group currency into one array
		for (const i in arr) {
			exchangesInArray.push({
				from_currency: i,
				rates: arr[i]
			})
		}

		return exchangesInArray.filter(el => {
			if (activeCurrency.find(
				activeCurrency => activeCurrency.code === el.from_currency
			) && activeCurrency.find(
				activeCurrency => activeCurrency.code === el.from_currency
			)) return true
			return false;
		})
	}
}